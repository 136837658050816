<template>
  <div>

    <!-- Header -->
    <div class="grid grid-cols-2">
      <div class="justify-self-start">
        <h1>Messages</h1>
      </div>
      <div class="justify-self-end">
        <router-link :to="`/projects/${projectId}/messages/create`" class="p-2 pr-3 pl-3 text-white font-light bg-gray-700 rounded inline-block">
          New message
        </router-link>
      </div>
    </div>

    <!-- Table -->
    <vue-good-table
      :columns="columns"
      :rows="messages"
      :search-options="{
        enabled: true,
        placeholder: 'Search for an message...'
      }"
      :pagination-options="{
        enabled: true,
        perPage: 50,
        dropdownAllowAll: false,
        perPageDropdownEnabled: false,
      }"
    >
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field == 'options'">
          <router-link :to="`/projects/${projectId}/messages/${props.row.id}`">
            <button class="pt-1 pr-2 pb-1 pl-2 text-white font-light tracking-wider bg-gray-500 rounded mr-3">
              Edit
            </button>
          </router-link>
          <button class="pt-1 pr-2 pb-1 pl-2 text-white font-light tracking-wider bg-red-500 rounded" @click="deleteMessage(props.row.id)">
            Delete
          </button>
        </div>
        <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: ['projectId'],

  data () {
    return {
      messages: [],
      columns: [
        {
          label: 'Message',
          field: 'text',
          tdClass: 'vgt-left-align',
        },
        {
          field: 'options',
          tdClass: 'vgt-left-right',
          width: '152px',
          sortable: false,
        },
      ]
    }
  },

  methods: {
    deleteMessage(id) {
      if (confirm('Are you sure?')) {
        axios.delete(`${process.env.VUE_APP_API_URL}/projects/${this.projectId}/messages/${id}`)
          .then(() => {
            this.$router.go(0);
          })
          .catch((error) => {
            console.log(error);
          })
      }
    }
  },

  created () {
    axios.get(`${process.env.VUE_APP_API_URL}/projects/${this.projectId}/messages`)
      .then(response => {
        this.messages = response.data;
      })
      .catch(error => {
        console.log(error)
      })
  }
}
</script>